<template>
    <Overview 
        :moduleName="'Koppeling'"
        :moduleNamePlural="'Koppelingen'"
        :sort="'customer_id'"
        :itemService=itemService
        :itemFormRef=itemFormRef
        :disableDelete=!isAdmin
        :disableNewButton=!isAdmin
    >

        <template #columns>
            <Column field="customer.name" header="Klant" :sortable="true"></Column>

            <Column header="Bron" style="width: 30%">
                <template #body="slotProps">
                    {{slotProps.data.source_connections[0].source.name}}
                </template>
            </Column>

            <Column header="Doel" style="width: 30%">
                <template #body="slotProps">
                    {{slotProps.data.destination_connections[0] ? slotProps.data.destination_connections[0].destination.name : null}}
                </template>
            </Column>

            <Column header="Importeren vanuit bron" style="width: 10%" v-if="isAdmin">
                <template #body="slotProps">
                    <template v-if="slotProps.data.source_connections[0].api_key">
                        <button class="btn btn-primary" @click="syncInvoices(slotProps.data.id)"> Importeer </button> 
                    </template>
                    <template v-else>
                        Aanmelding vereist
                    </template>
                </template>
            </Column>

            <Column header="Exporteren naar doel" style="width: 10%" v-if="isAdmin">
                <template #body="slotProps">
                    <template v-if="slotProps.data.destination_connections[0].oauth_accepted">
                        <button class="btn btn-secondary" @click="exportInvoices(slotProps.data.id, slotProps.data.destination_connections[0].id)"> Exporteer </button> 
                    </template>
                    <template v-else>
                        Aanmelding vereist
                    </template>
                </template>
            </Column>
            <Column header="Doel status" :sortable="true"  style="width: 10%">
            <template #body="slotProps">
                <p @click="slotProps.data.show = !slotProps.data.show">{{slotProps.data.destination_connections[0].status}}<template v-if="slotProps.data.destination_connections[0].last_error">(!)</template></p>
                <template v-if="slotProps.data.destination_connections[0].last_error && slotProps.data.show">
                    <pre>{{slotProps.data.destination_connections[0].last_error}}</pre>
                </template>
            </template>
            </Column>
        </template>

        <template #form>
            <ConnectionForm ref="ItemForm" :itemData="item" />
        </template>
    </Overview>
</template>

<script>
import Overview from '@/components/Overview.vue';
import Column from 'primevue/column';
import ConnectionForm from '@/components/connection/ConnectionForm.vue';
import connectionService from '@/services/ConnectionService';
import invoiceService from '@/services/InvoiceService';
import store from '@/store/user'

export default {
    components: {
        Overview,
        Column,
        ConnectionForm
    },
    data() {
        return {
            item: {},
            itemService: connectionService,
            itemFormRef: null,
            loading: false
        }
    },
    computed: {
        isAdmin () {
            return store.getters.isAdmin
        }
    },
    methods: {
        syncInvoices(connectionId){
            this.loading = true
            connectionService.syncInvoices(connectionId).catch(error => {
                console.log('error', error)
                this.toastError(error)
            }).finally(() => {
                this.loading = false
            })
        },
        exportInvoices(connectionId, destinationId){
            this.loading = true
            invoiceService.exportInvoices(connectionId, destinationId).catch(error => {
                console.log('error', error)
                this.toastError(error)
            }).finally(() => {
                this.loading = false  
            })
        }
    },
    mounted() {
        this.itemFormRef = this.$refs.ItemForm;
    }
}
</script>