import http from '@/http-common'
import {ApiService} from '@/services/ApiService'

const URL = '/api/invoice'

class InvoiceService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    exportInvoices(connectionId, destinationId, invoices){
        return http.post(`${this.getUrl()}/export`, {
            "connection_id": connectionId,
            "destination_id": destinationId,
            "invoices": invoices
        })
    }
}

export default new InvoiceService()