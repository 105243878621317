<template>
    <h2>Klantgegevens</h2>
    <div class="row">
        <label for="name" class="col-sm-4 col-form-label">Klant</label>
        <div class="col-sm-8">
                
            <select v-model="connection.customer_id" class="form-select form-select-sm" id="customer_id">   
                <option v-for="customer in customers" :key="customer.id" :value="customer.id" >
                    {{customer.name}}
                </option>
            </select>
        </div>
    </div>

    <h2>Brongegevens</h2>

    <div class="form-check">
        <input class="form-check-input" type="radio" name="source_id" id="source_id_1" v-model="connection.source_connections[0].source_id" :value="1">
        <label class="form-check-label" for="source_id_1">
            MICE Operations
        </label>
    </div>
    <div class="mb-3 row">
        <label for="api_key" class="col-sm-4 col-form-label">API key</label>
        <div class="col-sm-8">
            <input type="text" class="form-control" id="api_key" v-model="connection.source_connections[0].api_key" >
        </div>
    </div>
    <div class="row">
        <label for="name" class="col-sm-4 col-form-label">Synchroniseren vanaf</label>
        <div class="col-sm-3">
            <input type="date" v-model="connection.source_connections[0].export_start_date"
            class="form-control form-control-sm" id="export_start_date" />
        </div>
    </div>
    
    <h2>Doelgegevens</h2>
    
    <div class="row">
        <template v-for="destination in destinations" :key="destination.id">
            <div class='col-sm-4' v-if="destination.id == 1 ||destination.id == 4 || destination.id == 12 || destination.id == 2">
                <div class="form-check">
                    <input class="form-check-input" v-model="connection.destination_connections[0].destination_id" type="radio" name="destination_id" :id="'destination_id_' + destination.id" :value="destination.id">
                    <label class="form-check-label" :for="'destination_id_' + destination.id">
                        {{destination.name}}
                    </label>
                </div>
            </div>
        </template>
    </div>

    <div class="row">
        <label for="name" class="col-sm-4 col-form-label">Automatisch import/export</label>
        <div class="col-sm-8">
        <input type="checkbox" v-model="connection.automatic_transfers" :true-value="true"
            :false-value="false" class="form-check-input" id="automatic_transfers" />
        </div>
    </div>
    
    <template v-if="connection.id">
        <p></p>
           
        <template v-if="oauthDestinations.includes(connection.destination_connections[0].destination_id)">
          
             
        <template  v-if="connection.destination_connections[0].destination_id == 4">
            <div class="row">
                <label for="name" class="col-sm-4 col-form-label">Land</label>
                <div class="col-sm-8">
                    <select v-model="connection.destination_connections[0].country" class="form-select form-select-sm" id="country">   
                        <option v-for="country in countries" :key="country.id" :value="country.id" >
                            {{country.name}}
                        </option>
                    </select>
                </div>
            </div>
        </template>

            <div class="row">
                <label for="name" class="col-sm-4 col-form-label">OAuth</label>
                <div class="col-sm-8">
                    <button class='btn btn-sm' :class="{'btn-primary' : !connection.destination_connections[0].oauth_accepted, 'btn-secondary' : connection.destination_connections[0].oauth_accepted}" @click.prevent="oauth">Aanmelden</button> 
                    <template v-if="connection.destination_connections[0].oauth_accepted"> 
                        <template v-if="connection.destination_connections[0].username">
                            Huidige gebruiker: {{connection.destination_connections[0].username}} 
                        </template>
                        <template v-else>
                            Koppeling actief
                        </template>
                    </template>
                </div>
            </div>
            <template  v-if="connection.destination_connections[0].destination_id == 12">
                <div class="row">
                    <label for="administration" class="col-sm-4 col-form-label">Administratie</label>
                    <div class="col-sm-8">
                      <input v-model="connection.destination_connections[0].administration" class="form-control form-control-sm" id="administration" />
                    </div>
                </div>
                <div class="row">
                    <label for="costplace" class="col-sm-4 col-form-label">Kostenplaats</label>
                    <div class="col-sm-8">
                      <input v-model="connection.destination_connections[0].costplace" class="form-control form-control-sm" id="costplace" />
                    </div>
                </div>
            </template>
        </template>
        <template v-else>
            <template  v-if="connection.destination_connections[0].destination_id == 1">
                 <div class="row">
                    <label for="administration" class="col-sm-4 col-form-label">Omgevingscode</label>
                    <div class="col-sm-8">
                      <input v-model="connection.destination_connections[0].environment" class="form-control form-control-sm" id="environment" />
                    </div>

                    <label for="administration" class="col-sm-4 col-form-label">Token</label>
                    <div class="col-sm-8">
                      <input v-model="connection.destination_connections[0].access_token" class="form-control form-control-sm" id="access_token" />
                    </div>
                    <label for="administration" class="col-sm-4 col-form-label">Administratie</label>
                    <div class="col-sm-8">
                      <input v-model="connection.destination_connections[0].administration" class="form-control form-control-sm" id="administration" />
                    </div>
                    <label for="next_debtor_number" class="col-sm-4 col-form-label">Eerst volgend debiteur nr.</label>
                    <div class="col-sm-8">
                      <input v-model="connection.destination_connections[0].next_debtor_number" class="form-control form-control-sm" id="next_debtor_number" />
                    </div>
                    
                 </div>    
            </template>
            <template v-else>  
            <div class="row">
                <label for="username" class="col-sm-4 col-form-label">Gebruikersnaam</label>
                <div class="col-sm-8">
                    <input v-model="connection.destination_connections[0].username" class="form-control form-control-sm" id="username" />
                </div>
            </div>

            <div class="row">
                <label for="password" class="col-sm-4 col-form-label">Wachtwoord</label>
                <div class="col-sm-8">
                    <input v-model="connection.destination_connections[0].password" class="form-control form-control-sm" id="password" type="password"/>
                </div>
            </div>
            </template>
        </template>

        <div class="row">
            <label for="journal" class="col-sm-4 col-form-label">Dagboek</label>
            <div class="col-sm-8">
                <input v-model="connection.destination_connections[0].journal" class="form-control form-control-sm" id="journal"
                    required />
            </div>
        </div>

        <div class="row">
            <label for="default_glaccount" class="col-sm-4 col-form-label">Standaard grootboekrekening</label>
            <div class="col-sm-8">
                <input v-model="connection.destination_connections[0].default_glaccount" class="form-control form-control-sm" id="default_glaccount"
                    required />
            </div>
        </div>
        
        <template  v-if="connection.destination_connections[0].destination_id == 4">
            <div class="row">
                <label for="name" class="col-sm-4 col-form-label">Uitgestelde omzet</label>
                <div class="col-sm-8">
                <input type="checkbox" v-model="connection.destination_connections[0].deferred_revenue" :true-value="true"
                    :false-value="false" class="form-check-input" id="deferred_revenue" />
                </div>
            </div>
        </template>

        <p></p>
        <h2>BTW codes (Exclusief BTW)</h2>

        <div class="row" v-for='vatcode of connection.destination_connections[0].vat_codes' :key=vatcode.id>
            <label for="name" class="col-sm-4 col-form-label">{{vatcode.description}}</label>
            <div class="col-sm-8">
                <input class="form-control form-control-sm" v-model="vatcode.code" />
            </div>
        </div>
    </template>
</template>

<script>
    import destinationService from '@/services/DestinationService';
    import customerService from '@/services/CustomerService';

    export default {
        name: 'ConnectionForm',
        props: ['itemData'],
        data() {
            return {
                connection: {
                    'source_connections': [{
                        'source_id': 1,
                        'api_key': null,
                        'export_start_date': null
                    }],
                    'destination_connections': [{
                        'destination_id': null,
                        'country': null,
                        'journal': null,
                        'default_glaccount': null,
                        'administration': null,
                        'next_debtor_number': null,
                        'environment': null
                    }],
                    'customer_id': null,
                    'vat_codes': [  
                        {'code': '', 'description': '0%', 'percentage' : 0},
                        {'code': '', 'description': '9%','percentage' : 9},
                        {'code': '', 'description': '21%','percentage' : 21},
                        {'code': '', 'description': 'Verlegd','percentage' : 0},                
                    ]
                },
                countries: [
                    {id: 'NL', name: 'Nederland'},
                    {id: 'BE', name: 'België'}
                ],
                afasdestinations: [
                    {id: 'afasonlineconnector.nl', name: 'afasonlineconnector.nl'},
                    {id: 'soap.afas.online', name: 'soap.afas.online'},
                    {id: 'soaptest.afas.online', name: 'soaptest.afas.online'}
                ],
                customers: [
                    {id: null, name: ''}
                ],
                destinations: [],
                submitted: false,
                loading: false,
                invalid: {},

                oauthDestinations: [
                    2,  //Snelstart
                    4,  //Exact Online
                    12, //Twinfield

                ]

            }
        },
        watch: {
            itemData: function (val) {
                this.connection = val    
            },
        },
        methods: {
            validate(){
                this.invalid = {};
                let valid = true;

                let required = document.querySelectorAll('[required]');

                // for(let field of required) {
                //     let fieldname = field.id;
                    
                //     if(!this.connection[fieldname]) {
                //         this.invalid[fieldname] = true;
                //         valid = false;
                //     }
                // }

                // console.log("required", required);
                return valid;
            },
            getData() {
                return this.connection;
            },
            setData(data) {
                this.connection = data
            },
            reset() {
                this.connection = {
                    'source_connections': [{
                        'source_id': 1,
                        'api_key': null,
                    }],
                    'destination_connections': [{
                        'destination_id': null,
                        'country': null,
                        'journal': null,
                        'default_glaccount': null,
                        'deferred_revenue': null,
                        'automatic_transfers': null,
                        'administration': null,
                        'next_debtor_number':null,
                        'environment':null
                    }],
                    'customer_id': null
                },
                this.loading = false
                this.invalid = {}
            },
            oauth() {
                destinationService.getAuthUrl(this.connection.id).then(response => {
                    location.assign(response.data.url)
                }).catch(error => {
                    console.log('error', error)
                    this.toastError(error)
                })
            },
            getDestinations() {
                destinationService.index().then(response => {
                    this.destinations = response.data
                }).catch(error => {
                    console.log('error', error)
                })    
            },
            getCustomers() {
                customerService.index().then(response => {
                    this.customers = response.data
                }).catch(error => {
                    console.log('error', error)
                })    
            }
            
        },
        mounted() {
            this.getDestinations();
            this.getCustomers();
        }
    }
</script>
